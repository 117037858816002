.FooterStyle {
    background-color: #090e21;
    margin-top: 50px;
    padding-bottom: 30px;
    border-top: 1px solid #EEE;
    border-top-left-radius:30px;
    border-top-right-radius:30px;
   
}

.logoStyle {
    width: 200px;
}

.arrowStyle {
    width: 30px;
    animation: arrowAnimation 1s infinite alternate;
}

.iconStyle {
    width: 30px;
}

@keyframes arrowAnimation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(5px);
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
        -ms-transform: translateX(5px);
        -o-transform: translateX(5px);
}
}

.TextStyle  {
    width: 175px;
}
 

.iconText {
    width: 265px;
}

.visionStyle {
    width: 100px;
}

@media(max-width : 767px) {
    .iconText {
        width: 185px;
    }
    
}