.pattern {
  background-image: url("../Assets/Img/pattern.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

  .fixedImage {
    position: fixed;
    bottom: 10px;
    width: 100px;
    right: 10px;
    z-index: 1000; /* Ensure it appears above other content */
    width: 60px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
  }


  .chatStyle {
    bottom: 80px;
  }