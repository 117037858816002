@import url("https://fonts.googleapis.com/css2?family=Blaka&family=Blaka+Hollow&family=Cairo:wght@200..1000&family=Jomhuria&family=Lalezar&family=Marhey:wght@300..700&display=swap");

body {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ddc778;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ddc778bd;
}
