.HeaderStyle {
  background-color: #090e21;
}

.iconsStyle {
  width: 25px;
}

.logoStyle {
  width: 60px;
}

.textStyle:hover {
  color: #b68d33 !important;
}

.textStyle {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
